import { AccountContainer } from 'containers/Account';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/main.scss';
import { RUSSIAN_LOCALE } from 'utils';

// todo add localization feature
export const Account = observer(({ section }: { section: string }) => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const { isMediaTablet } = useWindowWidth();

  const isClient = userStore.clients && userStore.clients.count > 0;

  useEffect(() => {
    if (!section) {
      !isMediaTablet && navigate('personal');
    }

    if (section === 'change-password' && RUSSIAN_LOCALE) navigate('/');

    if (section === 'employees' && !isClient) navigate('/');
  }, [isMediaTablet]);

  return <AccountContainer section={section} />;
});
