import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from './SelectNew.module.scss';
import clsx from 'classnames';
import UiIcon from 'components/shared/Icon';
import { useTranslation } from 'react-i18next';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { SelectData, SelectNewProps } from './SelectNewProps';
import { RUSSIAN_LOCALE } from 'utils';
import { CompanySizeDictionary } from 'types/user';
import { getLangName } from 'tools/utils';
import { Radio } from '../Radio';

export const SelectNew: FC<SelectNewProps> = ({
  title,
  onClick,
  options,
  select,
  setSelect,
  defaultValue,
  onSelect,
  error,
  className,
  placeholder,
  disabled,
  isSolutionForm,
  endIcon,
  isShowDescription = false,
  small,
  usePortal = false,
  isInputFocused = false,
  isInInput = false,
  isType = false,
  classNameRadioDescription,
  classNameTitle,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
  } = endIcon || {};

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (open && usePortal && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [open, usePortal]);

  const renderDescription = (description: string) => {
    if (description) {
      return <span className={classes.additionalValue}> {description}</span>;
    }
  };

  const dropdownContent = (
    <div
      ref={dropdownRef}
      className={clsx(
        classes.dropdown,
        !isType && classes.dropdownRadio,
        small && !isType && classes.dropdownRadioSmall
      )}
      style={{
        position: usePortal ? 'fixed' : 'absolute',
        top: usePortal ? dropdownPosition.top : undefined,
        left: usePortal ? dropdownPosition.left : undefined,
        width: usePortal ? dropdownPosition.width : undefined,
        zIndex: 9999,
      }}
    >
      <div className={classes.list}>
        {options &&
          options.map(
            (item: SelectData | CompanySizeDictionary, index: number) => {
              if (item && 'type' in item) {
                return (
                  <div
                    key={`${item.type}${index}`}
                    onClick={(e) => {
                      setOpen(false);
                      if (item?.type !== select?.type) {
                        onSelect?.(item);
                        setSelect?.(item);
                      }
                    }}
                    className={clsx(
                      classes.item,
                      item?.type === select?.id && classes.active
                    )}
                  >
                    <span>{getLangName(item, 'name', i18n)}</span>
                    {item?.type === select?.type && (
                      <UiIcon
                        name="CheckIcon"
                        additionalClassName={classes.checkIcon}
                      />
                    )}
                  </div>
                );
              } else {
                return (
                  <Radio
                    key={`${item.id}${index}`}
                    name="item"
                    value={item}
                    checked={item?.id === select?.id}
                    onChange={() => {
                      setOpen(false);
                      if (item?.id !== select?.id) {
                        onSelect?.(item);
                        setSelect?.(item);
                      }
                    }}
                    label={item?.name ? t(item.name) : ''}
                    description={item?.description ? t(item.description) : ''}
                    classNameDescription={clsx(
                      classes.radioDescription,
                      !RUSSIAN_LOCALE && classes.radioDescriptionRU,
                      classNameRadioDescription
                    )}
                    classNameTitle={clsx(
                      classes.radioTitle,
                      !RUSSIAN_LOCALE && classes.radioTitleRU,
                      classNameTitle
                    )}
                  />
                );
              }
            }
          )}
      </div>
    </div>
  );

  return (
    <div className={className}>
      <div
        className={clsx(
          classes.selectBox,
          disabled && classes.selectBox__disabled
        )}
        ref={selectRef}
        onClick={() => onClick && onClick()}
      >
        <div className={classes.wrapper}>
          <div
            onClick={() => !disabled && setOpen(!open)}
            className={clsx(
              classes['main-input'],
              open && classes.dropActive,
              error && classes.error,
              isSolutionForm && classes['main-input__solutionForm'],
              small && classes.small,
              disabled && classes['main-input-disabled'],
              isInInput && classes['main-input-inInput'],
              (isInputFocused || open) && classes['main-input-focused'],
              endIconName && classes['main-input-endIcon']
            )}
          >
            <div
              className={clsx(
                classes.inputBlock,
                disabled && classes.inputBlock__disabled
              )}
            >
              <span
                className={clsx(
                  classes.inputTitle,
                  select ? classes.inputTitleSelected : ''
                )}
              >
                {title}
              </span>
              <div className={classes.drop}>
                {select && 'type' in select
                  ? getLangName(select, 'name', i18n)
                  : t(select?.name || defaultValue?.name)}
                {isShowDescription &&
                  renderDescription(
                    select?.description || defaultValue?.description
                  )}
              </div>
            </div>

            <div className={clsx(classes.arrow, open && classes.arrowActive)}>
              <ArrowDownIcon />
            </div>
          </div>

          {endIconName && (
            <div className={classes.endIconContainer}>
              <TooltipContainer
                text={endIconTooltipText}
                customClasses={'kit-ui-block'}
                position={endIconTooltipPosition}
                className={endIconTooltipClassName}
              >
                <UiIcon
                  name={endIconName}
                  additionalClassName={clsx(
                    classes.endIcon,
                    endIconClassName,
                    error && classes.errorIcon
                  )}
                />
              </TooltipContainer>
            </div>
          )}

          {/* Using React Portal if isOverflow is true */}
          {open &&
            (usePortal
              ? ReactDOM.createPortal(
                  <div
                    className={
                      RUSSIAN_LOCALE ? 'dropdown rulocale' : 'dropdown enlocale'
                    }
                  >
                    {dropdownContent}
                  </div>,
                  document.body
                )
              : dropdownContent)}
        </div>
      </div>

      {error && (
        <div className={classes.errorText}>
          {error || t('Fill in the field')}
        </div>
      )}
    </div>
  );
};
