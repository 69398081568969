import clsx from 'clsx';
import classes from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { LinksPolitic } from 'components/shared/LinksPolitic';

export const Footer = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <footer className={clsx(classes.footer, className && classes[className])}>
      <div className="layout-inner">
        <div className={classes.inner}>
          <div className={classes.copy}>
            &copy; {new Date().getFullYear()} {t('KIT Global_footer')}
          </div>

          <LinksPolitic />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
