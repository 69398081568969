export enum PASS_MODE {
  OLD = 'oldPass',
  NEW = 'newPass',
  CONFIRM = 'newPassConfirmation',
}

export enum AMOUNT_TYPE {
  MORE = '>',
  LESS = '<',
  PLUS = '+',
  NONE = '',
}

export enum AMOUNT_UNIT {
  CUR = '$',
  PERC = '%',
  NONE = '',
  RUB = '₽',
}

export enum COST_TYPE {
  FROM = 'COST_TYPE_FROM',
  TO = 'COST_TYPE_TO',
}

export enum DEAL_STAGES {
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  PAUSED = 'paused',
  ALL = 'all',
  ACTIVE = 'active',
}

export enum PLATFORM {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export enum COMPANY_SIZE {
  MICRO = 'MICRO',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  CORPORATION = 'CORPORATION',
}

export enum DICTIONARY_FIELDS {
  business_types = 'business_types',
  service_types = 'service_types',
  payment_types = 'payment_types',
  company_size = 'company_size',
  goals = 'goals',
  cost_type = 'cost_type',
  cost_per = 'cost_per',
  deal_stages = 'deal_stages',
  discount_types = 'discount_types',
  discount_cost_change_types = 'discount_cost_change_types',
  invoice_statuses = 'invoice_statuses',
  case_kpi = 'case_kpi',
}
