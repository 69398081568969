import classes from './Button.module.scss';
import clsx from 'clsx';
import { ButtonProps } from './ButtonProps.props';
import { RUSSIAN_LOCALE } from 'utils';

export const Button = ({
  className: additionalClassName,
  size,
  theme,
  children,
  onClick,
  disabled,
  label,
  type,
}: ButtonProps) => {
  const className = clsx(
    classes.btn,
    theme && classes[theme],
    size && classes[size],
    RUSSIAN_LOCALE && classes.ru,
    additionalClassName
  );

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
      aria-label={label}
    >
      {children}
    </button>
  );
};
