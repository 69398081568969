import clsx from 'clsx';
import { Pagination } from 'components/shared/Pagination';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { LABELS } from 'constants/onboarding';
import 'containers/CardsContainer/IntroTooltip.css';
import { EmptyItems } from 'containers/EmptyItems';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import { ProjectCard } from './Card';
import { Filters } from './Filters';
import classes from './Projects.module.scss';
import { useProjects } from './useProjects';
import { ProjectProps } from 'types/project';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';

export const ProjectsContainer = observer(() => {
  const {
    projects,
    t,
    setFilter,
    filter,
    cancelledCount,
    pausedCount,
    prodCount,
    allCount,
    activeCount,
    chunks,
    projectsToPaginate,
    currentPage,
    goToTop,
    setCurrentPage,
  } = useProjects();

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = projects.length === 0 ? 'PROJECTS_ZERO' : 'PROJECTS';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps(onboardingPath).then((res) => setOnboarding(res));
  }, [onboardingPath]);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  return (
    <>
      <TitleAndDescription pageName={'Projects'} />

      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
            scrollToElement: false,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <div
        className={clsx(
          classes.wrapper,
          projects.length === 0 && 'empty-background'
        )}
      >
        <div style={{ position: 'relative', zIndex: 1 }}>
          <div className={classes.title}>
            {t('Projects')}
            <span>{allCount}</span>
          </div>

          <Filters
            all={allCount}
            active={activeCount}
            completed={prodCount}
            paused={pausedCount}
            cancelled={cancelledCount}
            filter={filter}
            setFilter={setFilter}
          />
        </div>

        <div className={classes.grid} data-step-projects-0>
          {(chunks.length > 1 ? chunks[currentPage - 1] : chunks[0])?.map(
            (project: ProjectProps) => (
              <ProjectCard key={project.number} project={project} />
            )
          )}

          {projects.length === 0 && (
            <EmptyItems
              isBackground={false}
              title={'You have no projects'}
              description={
                'To initiate a project, select a Solution and add it to cart'
              }
            />
          )}
        </div>

        {projectsToPaginate.length > 3 && (
          <div className={classes.pagination}>
            <Pagination
              currentPage={currentPage}
              totalPages={chunks.length}
              setPageNumber={setCurrentPage}
              extraAction={() => goToTop()}
            />
          </div>
        )}
      </div>
    </>
  );
});
