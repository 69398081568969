import { AxiosResponse } from 'axios';
import api from './http';

export type OrderingTransactions =
  | '-amount'
  | 'amount'
  | '-created_at'
  | 'created_at'
  | undefined;
export type QueryParamsTransactions = {
  page: number | null;
  limit: number | null;
  ordering: OrderingTransactions;
};

export default class Finances {
  static async getConditions(): Promise<AxiosResponse> {
    return api.get(
      '/finance/api/v1/refill/conditions/?service=pay_connect&currency=RUB'
    );
  }

  static async getTransactions({
    page,
    limit,
    ordering,
  }: QueryParamsTransactions): Promise<AxiosResponse> {
    return api.get(
      `/finance/api/v1/user-transactions/?${page ? `page=${page}` : 'page=1'}${
        limit ? `&page_size=${limit}` : ''
      }${ordering ? `&ordering=${ordering}` : ''}`
    );
  }

  static async payTransaction(formData: FormData): Promise<AxiosResponse> {
    return api.post('/api/v1/finances/pay', formData);
  }
}
