import { authStore } from 'stores/authStore';
import { cartStore } from 'stores/cartStore';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { userStore } from 'stores/userStore';
import { ShowNotification } from './showNotification';

export function ReloadStores() {
  try {
    authStore.refreshAuth();
    userStore.loadUser();
    userStore.loadProjects({ reload: true });
    cartStore.loadCart();
    marketPlaceStore.loadServices();
    marketPlaceStore.loadTypes();
    return true;
  } catch {
    ShowNotification({
      type: 'error',
      children: 'Unknown error. Please, try again later',
    });
  }
}
