import { Button } from 'components/shared/Button';
import MobileFilterModal from 'components/common/MobileFilterModal';
import { Modal } from 'components/shared/Modal';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classes from './RestoreModal.module.scss';
import { RestoreModalProps } from './RestoreModal.props';
import { useTranslation } from 'react-i18next';

const RestoreModal = ({
  title,
  description,
  openModal,
  setOpenModal,
  setRecaptcha,
  handleRestore,
}: RestoreModalProps) => {
  const { t } = useTranslation();
  const { desktop, mobile } = openModal;
  const [disabled, setDisabled] = useState(true);
  const [captcha, setCaptcha] = useState<ReCAPTCHA | null>();

  const modalContent = (
    <div className={classes.modal}>
      <div className={classes.modal__content}>
        <span className={classes.modal__title}>{title}</span>
        <span className={classes.modal__description}>{description}</span>
        <div className={classes.modal__captcha}>
          <ReCAPTCHA
            ref={(el) => setCaptcha(el)}
            sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
            onChange={(token) => {
              if (token) {
                setRecaptcha(token);
                setDisabled(false);
              }
            }}
            hl="en"
            size="normal"
          />
        </div>
      </div>

      <div className={classes.modal__button_wrapper}>
        <Button
          className={classes.modal__button}
          onClick={() => {
            handleRestore();
            setOpenModal({ ...openModal, desktop: false, mobile: false });
            captcha?.reset();
          }}
          theme="primary"
          disabled={disabled}
        >
          {t('Resend email')}
        </Button>
      </div>
    </div>
  );

  return mobile ? (
    <MobileFilterModal
      title={title}
      titleClassName={classes.customTitle}
      onClose={() => setOpenModal({ ...openModal, mobile: false })}
      isOutsideBase
    >
      {modalContent}
    </MobileFilterModal>
  ) : (
    <Modal
      title=""
      isOpen={desktop}
      onClose={() => setOpenModal({ ...openModal, desktop: false })}
      isOutsideBase
    >
      {modalContent}
    </Modal>
  );
};

export default RestoreModal;
