import { DotsIcon } from 'components/shared/Icons/DotsIcon';
import classes from './MenuCell.module.scss';
import FilterSearch from 'components/shared/FilterSearch/FilterSearch';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useState } from 'react';
import { ModalMobile } from 'components/shared/ModalMobile';
import { MenuCellProps } from './MenuCell.props';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button';
import { employeesRoles } from '../../employeesRoles';
import { OptionTypeString } from 'types/util-types';
import { Radio } from 'components/shared/Radio';
import { CLIENT_ROLE } from 'types/user';
import clsx from 'clsx';

const MenuCell = ({
  value,
  handleDeletePermissions,
  handleChangePermissions,
}: MenuCellProps) => {
  const { isMediaTablet } = useWindowWidth();
  const { t } = useTranslation();

  const [isShowMenuModal, setIsShowMenuModal] = useState(false);
  const handleShowMenuModal = () => {
    setIsShowMenuModal(true);
  };
  const handleCloseMenuModal = () => {
    setIsShowMenuModal(false);
  };

  const [isShowDotsMenu, setShowDotsMenu] = useState(false);
  const handleShowDotsModal = () => {
    setShowDotsMenu(true);
  };
  const handleCloseDotsModal = () => {
    setShowDotsMenu(false);
  };

  const handleClose = () => {
    handleCloseDotsModal();
    handleCloseMenuModal();
    close();
  };

  const [typeUser, setTypeUser] = useState<CLIENT_ROLE>(value.permission.type);

  return (
    <>
      <div className={classes.dots}>
        {isMediaTablet ? (
          <DotsIcon onClick={() => handleShowMenuModal()} />
        ) : (
          <FilterSearch
            classNameForButton={classes.table__search}
            buttonElement={<DotsIcon />}
            isLastFilter={true}
          >
            {(close) => (
              <div onClick={handleShowDotsModal} className={classes.delete}>
                {t('Delete')}
              </div>
            )}
          </FilterSearch>
        )}
      </div>

      <ModalMobile
        title=""
        isOpen={isShowMenuModal}
        onClose={handleCloseMenuModal}
        isBasicHeader={false}
      >
        <div className={classes.inner}>
          <div className={classes.headerWrapper}>
            <h3
              className={clsx(
                classes.title,
                !value.id && classes.titleWithoutId
              )}
            >
              {value.full_name || value.email}
            </h3>
            {value.id && <h3 className={classes.titleEmail}>{value.email}</h3>}

            <div className={classes.radioGroup}>
              {employeesRoles.map((role: OptionTypeString) => (
                <Radio
                  key={role.id}
                  name="role"
                  value={role}
                  checked={typeUser === role.id}
                  onChange={(item) => setTypeUser(item.id as CLIENT_ROLE)}
                  label={role.name ? t(role.name) : ''}
                  description={role.description ? t(role.description) : ''}
                />
              ))}
            </div>
          </div>

          <div className={classes.buttons}>
            <Button
              className={classes.button}
              theme="primary"
              size="middle"
              onClick={async (e) => {
                e.stopPropagation();
                if (typeUser !== value.permission.type)
                  await handleChangePermissions(
                    value.id,
                    typeUser,
                    value.email
                  );

                handleClose();
              }}
            >
              {t('Save changes')}
            </Button>
            <Button
              className={classes.button}
              theme="primary-light"
              size="middle"
              onClick={handleShowDotsModal}
            >
              {t('Remove')}
            </Button>
          </div>
        </div>
      </ModalMobile>

      <ModalMobile
        title=""
        isOpen={isShowDotsMenu}
        onClose={handleCloseDotsModal}
        isBasicHeader={false}
        isOnCenter
      >
        <div className={classes.inner}>
          <div className={classes.headerWrapper}>
            <h3 className={classes.titleRemove}>
              {t('Remove {{ name }} {{ email }} from Account?', {
                name: value.full_name,
                email: value.email,
              })}
            </h3>
            <p className={classes['title-description-remove']}>
              {t(
                'Employee will no longer have access to Account. This action cannot be undone'
              )}
            </p>
          </div>

          <div className={classes.buttons}>
            <Button
              className={classes.button}
              theme="primary"
              size="middle"
              onClick={(e) => {
                e.stopPropagation();
                handleCloseDotsModal();
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              className={classes.button}
              theme="primary-light"
              size="middle"
              onClick={(e) => {
                e.stopPropagation();
                handleDeletePermissions(value.id, value.email);
                handleClose();
              }}
            >
              {t('Continue')}
            </Button>
          </div>
        </div>
      </ModalMobile>
    </>
  );
};

export default MenuCell;
