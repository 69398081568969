import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import classes from '../../CartCard.module.scss';
import { useTranslation } from 'react-i18next';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  isDisabled: boolean;
  itemName: string;
}

export const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onDelete,
  isDisabled,
  itemName,
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={''}
      isOpen={isOpen}
      className={classes.del_modal}
      onClose={onClose}
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalInner}>
          <span className={classes.modalTitle}>{t('Delete solution')}</span>

          <p
            className={classes.modalText}
            dangerouslySetInnerHTML={{
              __html: t(
                'Are you sure you want to remove {{ name }} from the Cart?',
                {
                  name: itemName,
                }
              ),
            }}
          />
        </div>

        <div className={classes.buttonModalWrap}>
          <Button
            theme="light"
            onClick={onClose}
            className={classes.buttonModal}
          >
            {t('Cancel')}
          </Button>

          <Button
            theme="primary"
            onClick={onDelete}
            disabled={isDisabled}
            className={classes.buttonModal}
          >
            {t('Delete')}
          </Button>
        </div>

        <button className={classes.modalClose} onClick={onClose}>
          <UiIcon
            name="CrossIcon"
            additionalClassName={classes['close-icon']}
          />
        </button>
      </div>
    </Modal>
  );
};
