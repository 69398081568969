import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const UserGradientIcon = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = React.useId(); // Используем уникальный id

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="none"
      {...props}
    >
      <defs>
        {RUSSIAN_LOCALE ? (
          <linearGradient id={`${gradientId}`} gradientTransform="rotate(150)">
            <stop offset="8.91%" stopColor="#e47267" />
            <stop offset="93.43%" stopColor="#eb5945" />
          </linearGradient>
        ) : (
          <linearGradient id={`${gradientId}`} gradientTransform="rotate(140)">
            <stop offset="0%" stopColor="#6e3cff" />
            <stop offset="100%" stopColor="#4ba5ff" />
          </linearGradient>
        )}
      </defs>

      <path
        d="M12 13.1739C16.3434 13.1739 19.8783 16.56 19.9969 20.7809L20 21H18.4C18.4 17.5422 15.5346 14.7391 12 14.7391C8.53207 14.7391 5.70837 17.4374 5.60305 20.805L5.6 21H4C4 16.6778 7.58172 13.1739 12 13.1739ZM12 3C14.651 3 16.8 5.10231 16.8 7.69565C16.8 10.289 14.651 12.3913 12 12.3913C9.34903 12.3913 7.2 10.289 7.2 7.69565C7.2 5.10231 9.34903 3 12 3ZM12 4.56522C10.2327 4.56522 8.8 5.96676 8.8 7.69565C8.8 9.42454 10.2327 10.8261 12 10.8261C13.7673 10.8261 15.2 9.42454 15.2 7.69565C15.2 5.96676 13.7673 4.56522 12 4.56522Z"
        fill={`url(#${gradientId})`}
      />
    </svg>
  );
};
