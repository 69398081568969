import React, { FC, useState, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import clsx from 'clsx';
import classes from './DashboardContainer.module.scss';
import Select from '../../components/shared/SelectComponent';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import emptyDashboard from 'assets/img/Dashboard/EmptyDashboard.svg';
import emptyDashboard_ru from 'assets/img/Dashboard/EmptyDashboard_ru.svg';
import { TitleAndDescription } from '../../components/shared/TitleAndDescription/TitleAndDescription';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useUserStore } from 'contexts/UserContext';
import { GetDashboardParams } from 'services/api/getDashboardParams';
import { GetDemoDashboardParams } from 'services/api/getDemoDashboardParams';
import { GetDashboardProjects, GetDemoDashboards } from 'services/api/getDashboardProjects';
import { RUSSIAN_LOCALE } from 'utils';
import { DashboardPowerBI } from './DashboardPowerBI';
import { userStore } from 'stores/userStore';

interface DashboardContainerProps {
  title: string;
}

export const DashboardContainerRU: FC<DashboardContainerProps> = ({ title }) => {
    const { t } = useTranslation();


    const [dashboardUserProjects, setDashboardUserProjects] = useState<any>([]);
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [openSelect, setOpenSelect] = useState(false);
    const { isMediaTablet } = useWindowWidth();
    const [cookies, setCookies] = useCookies(['access', 'refresh']);
    const [selectedReportID, setSelectedReportID] = useState("");
    const [selectedEmbedUrl, setSelectedEmbedUrl] = useState("");
    const [currentAccessToken, setCurrentAccessToken] = useState("");
    const [dashboardSelectTitle, setDashboardSelectTitle] = useState(t('Projects'));

    useEffect(() => {
    const getDashboardProjects = async () => {
    try {
      const response = await GetDashboardProjects();
      return response;
    } catch (error) {
      console.log(error, 'Get DashboardParams Error');
    }
    };
    const response = getDashboardProjects();
    const json = response.then(function (projects) {
          console.log(projects.results, 'Dashboard projects');
          setDashboardUserProjects(projects.results);
          if (projects.results?.length > 0) {
            setSelectedProject(projects.results?.[0]);
          }
          else {
            setDashboardSelectTitle(t('Demo Dashboards'));
            const response = GetDemoDashboards();
            const json = response.then(function (demoDashboards) {
                console.log(demoDashboards, 'Demo Dashboards');
                setDashboardUserProjects(demoDashboards);
                setSelectedProject(demoDashboards?.[0]);
            });
          }
        });
    }, []);
    useEffect(() => {
    if (!!selectedProject) {
        if (selectedProject?.is_demo) {
            console.log(selectedProject, 'demo selected project')
            const response = GetDemoDashboardParams({
                    access: cookies.access,
                    refresh: cookies.refresh,
                    reportID: selectedProject?.report_id
            });
            const json = response.then(function (params) {
                  console.log(params, 'Dashboard Demo params');
                  setSelectedReportID(params?.reportConfig.reportID);
                  setSelectedEmbedUrl(params?.reportConfig.embedUrl);
                  setCurrentAccessToken(params?.accessToken);
                });
        }
        else {
             const response = getDashBoardParams(
                 selectedProject.id,
                 selectedProject.powerbi_report_id,
                );
            const json = response.then(function (params) {
                  console.log(params, 'Dashboard params');
                  setSelectedReportID(params?.reportConfig.reportID);
                  setSelectedEmbedUrl(params?.reportConfig.embedUrl);
                  setCurrentAccessToken(params?.accessToken);
                });
        }
    }
    }, [selectedProject]);

    const getDashBoardParams = async (projectID: string, reportID: string) => {
    try {
      const response = await GetDashboardParams({
        access: cookies.access,
        refresh: cookies.refresh,
        projectID: projectID,
        reportID: reportID,
      });
      return response;
    } catch (error) {
      console.log(error, 'Get DashboardParams Error');
    }
    };


    const emptyPage = (
    <div className={clsx(classes.emptyPage, 'empty-background')}>
      <div className={classes.emptyPage__icon}>
        <img
          src={RUSSIAN_LOCALE ? emptyDashboard_ru : emptyDashboard}
          alt={t('This information is only available on the desktop version')}
          className={classes.emptyPage__image}
        />
      </div>
      <div className={classes.emptyPage__text}>
        {t('This information is only available on the desktop version')}
      </div>
    </div>
    );
    return (
    <>
      <div className={clsx(classes.wrapper)}>
        <TitleAndDescription pageName="Dashboard" />
        <div className={classes.title}>{title}</div>
        {!isMediaTablet ? (
          <div className={classes.selectContainer}>
            <Select
              select={selectedProject}
              setSelect={setSelectedProject}
              options={dashboardUserProjects}
              open={openSelect}
              setOpen={setOpenSelect}
              title={dashboardSelectTitle}
              boxClassName={classes.selectBox}
              wrapperClassName={classes.selectWrapper}
            />
          </div>
        ) : (
          emptyPage
        )}
      </div>
      {!isMediaTablet && (
       <div className={clsx(classes.powerbi_dashboard)}>
           <DashboardPowerBI
              reportID={selectedReportID}
              embedUrl={selectedEmbedUrl}
              accessToken={currentAccessToken}
           />
       </div>
       )}
    </>
  );
};
