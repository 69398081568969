import { toast, ToastOptions } from 'react-toastify';
import { Toast } from 'components/shared/Toast';
import { cartStore } from 'stores/cartStore';
import { ReactNode } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';

interface Props {
  type: 'success' | 'warning' | 'error';
  children?: ReactNode;
  toastProps?: ToastOptions;
  title?: string;
}

export function ShowNotification({ type, children, toastProps, title }: Props) {
  const cartButtonSize = cartStore.fixedButtonSize;
  const position = window.innerWidth < 768 ? 'top-center' : 'top-right';

  return toast(
    <Toast title={title} type={type}>
      {String(children)}
    </Toast>,
    {
      ...toastProps,
      position,
      className:
        cartButtonSize !== 120 ? 'toast-container' : 'toast-container-big',
    }
  );
}
