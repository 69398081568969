import classes from './MobileFilter.module.scss';
import { useTranslation } from 'react-i18next';
import { MobileFilterProps } from './MobileFilter.props';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { OrderingTransactions } from 'services/finances';

const sortOptions = [
  { key: '-created_at', label: 'New first' },
  { key: 'created_at', label: 'Old first' },
  { key: '-amount', label: 'Cheap first' },
  { key: 'amount', label: 'Expensive first' },
];

export const MobileFilter = observer(
  ({ setOrdering, ordering }: MobileFilterProps) => {
    const { t } = useTranslation();

    return (
      <div className={classes.inner}>
        <div className={classes.header}>
          <h3 className={classes.title}>{t('Sort')}</h3>
        </div>

        <ul className={classes.list}>
          {sortOptions.map((option) => (
            <li
              key={option.key}
              onClick={() => setOrdering(option.key as OrderingTransactions)}
              className={clsx(
                classes.listItem,
                ordering === option.key && classes.listItemActive
              )}
            >
              {t(option.label)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
);
