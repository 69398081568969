import { DEAL_STAGES } from 'constants/enums';
import { useWindowWidth } from 'hooks/useWindowWidth';
import manager_ru from 'assets/img/default_manager_image_ru.svg';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'services/http';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './Card.module.scss';
import { ProjectProps } from 'types/project';

export const useProjectCard = (project: ProjectProps) => {
  const { t } = useTranslation();
  const isTablet = useWindowWidth().isMediaTablet;

  const manager_img = RUSSIAN_LOCALE
    ? manager_ru
    : `${API_URL}/static/core/img/default_manager_image.png`;

  const active_tags = [
    'ACTIVE',
    'CREATED',
    'ANALYSIS',
    'PROPOSAL',
    'DECISION',
    'CLOSING',
    'PRODUCTION',
    t('Cancelled'),
    t('Paused'),
    t('Completed'),
  ];

  const { COMPLETED, CANCELLED, PAUSED } = DEAL_STAGES;

  const status = String(project?.status).toLowerCase();
  const firstCapitalStatus =
    project?.status?.charAt(0)?.toUpperCase() +
    project?.status?.slice(1)?.toLowerCase();
  let tagClass = '';
  let tagText = '';
  const source =
    project?.source?.name &&
    (project?.source?.name?.includes('http')
      ? project?.source?.name
      : `https://${project?.source?.name}`);

  switch (status) {
    case PAUSED:
      tagClass = classes.card__tag_yellow;
      break;
    case COMPLETED:
      tagClass = classes.card__tag_purple;
      break;
    case CANCELLED:
      tagClass = classes.card__tag_red;
      break;
    default:
      tagClass = active_tags?.includes(project?.status)
        ? classes.card__tag_green
        : '';
      break;
  }

  tagText = (
    firstCapitalStatus === 'Active' ? t('ACTIVE') : t(firstCapitalStatus)
  )?.toUpperCase();

  const shortSource =
    source?.length > (isTablet ? 35 : 60)
      ? `${source?.slice(0, isTablet ? 35 : 60)}...`
      : source;

  return { tagClass, tagText, source, shortSource, t, manager_img };
};
