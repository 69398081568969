import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { CancelledIcon } from 'components/shared/Icons/CancelledIcon';
import { CheckCirleIconPurple } from 'components/shared/Icons/CheckCirleIconPurple';
import { DownloadIcon } from 'components/shared/Icons/DownloadIcon';
import { PausedIcon } from 'components/shared/Icons/PausedIcon';
import { PurpleEmptyIcon } from 'components/shared/Icons/PurpleEmptyIcon';
import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import loader from 'components/shared/PageLoader/loader.json';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { formatRuFullDate } from 'tools/formattedDate';
import { RUSSIAN_LOCALE } from 'utils';
import { DealStageProps } from './DealStage.props';
import classes from './ProjectDealStages.module.scss';
import { useDealStage } from './useDealStage';
import { ProjectFile, DealStage } from 'types/project';

export const ProjectsDealStagesContainer = observer(
  ({ project }: DealStageProps) => {
    const { t } = useTranslation();
    const {
      PAUSED,
      CANCELLED,
      formatDate,
      isMediaTablet,
      internalStageList,
      isInnerDataLoading,
    } = useDealStage(project);

    const getStepIcon = (
      stageName: string,
      type: string = '',
      id: number = 0,
      planEndDate: string = '',
      factEndDate: string = ''
    ) => {
      const projectFile = project?.files?.find(
        (file: ProjectFile) => file.deal_stage === id
      );
      const projectFilePath = projectFile?.file;
      const fileName = projectFile?.file_name
        ? projectFile?.file_name
        : projectFilePath?.match(/\/files\/(.+)/)?.[1];
      const shortFileName =
        fileName && fileName?.length > 20
          ? `${fileName?.slice(0, 20)}...`
          : fileName;

      const stageType = type.toLowerCase();

      if (planEndDate && factEndDate) {
        let factRuDate = null;

        if (RUSSIAN_LOCALE) {
          factRuDate = formatRuFullDate(factEndDate);
        }

        return (
          <div className={classes.step}>
            <div className={clsx(classes.name, classes.past)}>
              {stageType === CANCELLED ? (
                <CancelledIcon />
              ) : stageType === PAUSED ? (
                <PausedIcon />
              ) : (
                <CheckCirleIconPurple />
              )}

              <div className={classes.name__main}>
                <span>{stageName}</span>

                {projectFilePath && (
                  <a
                    href={projectFilePath}
                    target="_blank"
                    className={classes.map__link}
                    title={fileName}
                    rel="noreferrer"
                  >
                    <span>{shortFileName}</span>
                    <DownloadIcon />
                  </a>
                )}
              </div>
            </div>

            <TooltipContainer
              text={t('Fact date')}
              className={classes.tooltipContainer}
              classNameTooltip={classes.tooltip}
              tooltipInner={classes.tooltipInner}
              position="bottom"
            >
              <div className={classes.date}>
                {RUSSIAN_LOCALE ? factRuDate : formatDate(factEndDate)}
              </div>
            </TooltipContainer>
          </div>
        );
      } else if (planEndDate && !factEndDate) {
        let planRuDate = null;

        if (RUSSIAN_LOCALE) {
          planRuDate = formatRuFullDate(planEndDate);
        }

        return (
          <div className={clsx(classes.step, classes.empty)}>
            <div className={clsx(classes.name, classes.past)}>
              {stageType === CANCELLED ? (
                <CancelledIcon />
              ) : stageType === PAUSED ? (
                <PausedIcon />
              ) : (
                <PurpleTimeIcon />
              )}

              <div className={classes.name__main}>
                <span>{stageName}</span>

                {projectFilePath && (
                  <a
                    href={projectFilePath}
                    target="_blank"
                    className={classes.map__link}
                    title={fileName}
                    rel="noreferrer"
                  >
                    <span>{shortFileName}</span>
                    <DownloadIcon />
                  </a>
                )}
              </div>
            </div>

            <TooltipContainer
              text={t('Plan date')}
              className={classes.tooltipContainer}
              classNameTooltip={classes.tooltip}
              tooltipInner={classes.tooltipInner}
              position="bottom"
            >
              <div className={classes.date}>
                {RUSSIAN_LOCALE ? planRuDate : formatDate(planEndDate)}
              </div>
            </TooltipContainer>
          </div>
        );
      } else {
        return (
          <div className={clsx(classes.step, classes.empty)}>
            <div className={clsx(classes.name)}>
              <PurpleEmptyIcon />

              <div className={classes.name__main}>
                <span>{stageName}</span>
                {projectFilePath && (
                  <a
                    href={projectFilePath}
                    target="_blank"
                    className={classes.map__link}
                    title={fileName}
                    rel="noreferrer"
                  >
                    <span>{shortFileName}</span>

                    <DownloadIcon />
                  </a>
                )}
              </div>
            </div>

            <TooltipContainer
              text={t('Plan date')}
              className={classes.tooltipContainer}
              classNameTooltip={classes.tooltip}
              tooltipInner={classes.tooltipInner}
              position="bottom"
            >
              <div className={classes.date}>{formatDate(planEndDate)}</div>
            </TooltipContainer>
          </div>
        );
      }
    };

    return (
      <div className={classes.map}>
        {isInnerDataLoading ? (
          <div className={classes.loader}>
            <Lottie animationData={loader} />
          </div>
        ) : (
          <>
            {internalStageList?.map((stage: DealStage) => {
              if (stage?.id) {
                return getStepIcon(
                  stage?.name,
                  stage?.type,
                  stage.id,
                  stage?.plan_end_date,
                  stage?.fact_end_date
                );
              } else {
                return getStepIcon(stage?.name);
              }
            })}

            <div className={classes.buttonContainer}>
              <div
                className={clsx(
                  classes[`${isMediaTablet ? 'mobileContent' : 'content'}`]
                )}
              >
                <NavLink to={`/projects/${project.id}`}>
                  <Button theme="light">{t('See more')}</Button>
                </NavLink>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);
