import { FileIcon } from 'components/shared/Icons/FileIcon';
import classes from './ProjectDocuments.module.scss';
import { TFunction } from 'i18next';
import { handleDownload } from 'tools/fileDownload';
import {
  ProjectProps,
  ProjectFile,
  ProjectInvoice,
} from '../../../types/project';
import React from 'react';
import { InvoiceIcon } from 'components/shared/Icons/InvoiceIcon';

type ProjectDocumentsProps = {
  t: TFunction;
  project: ProjectProps | null;
  projectInvoices: ProjectInvoice | null;
};

function ProjectDocuments({
  project,
  t,
  projectInvoices,
}: ProjectDocumentsProps) {
  return (
    <div className={classes['documents-container']}>
      <ul className={classes['documents-list']}>
        {project?.files.map((doc: ProjectFile, index: number) => (
          <li
            key={`${index}-${doc.file}-${doc.size}`}
            className={classes['documents-list__item']}
          >
            <div className={classes['documents-list__item_icon']}>
              <FileIcon width={14} height={14} viewBox="0 0 14 14" />
            </div>
            <div className={classes['documents-list__item_info']}>
              <div className={classes['documents-list__item_header']}>
                <span
                  className={classes['documents-list__item_label']}
                  onClick={() => handleDownload(doc.file, true)}
                >
                  {doc.file_name || doc.file.split('/').pop()}
                </span>
                <span className={classes['documents-list__item_size']}>
                  {(doc.size / 1024 / 1024).toFixed(3) + ' ' + t('Mb')}
                </span>
              </div>
              <span className={classes['documents-list__item_desc']}>
                {doc.description || t('file')}
              </span>
            </div>
          </li>
        ))}
      </ul>
      <ul className={classes['documents-list']}>
        {projectInvoices?.results?.sub_invoices?.map((invoice, index) => (
          <li
            key={`${invoice.id}-${index}`}
            className={classes['documents-list__item']}
          >
            <div className={classes['documents-list__item_icon']}>
              <InvoiceIcon width={14} height={14} viewBox="0 0 14 14" />
            </div>
            <div className={classes['documents-list__item_info']}>
              <div className={classes['documents-list__item_header']}>
                <span
                  className={classes['documents-list__item_label']}
                  onClick={() => handleDownload(invoice.file, true)}
                >
                  {invoice.invoice.number ||
                    `Invoice #${invoice.serial_number}`}
                </span>
                {/* <span className={classes['documents-list__item_size']}>
                  {`${(Number(invoice.total) / 1024 / 1024).toFixed(3)} ${t('Mb')}`}
                </span> */}
              </div>
              <span className={classes['documents-list__item_desc']}>
                {invoice.invoice.description || t('No description available')}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProjectDocuments;
