import { NavLink } from 'react-router-dom';
import { RUSSIAN_LOCALE } from 'utils';
import classes from '../../Header.module.scss';
import { Button } from 'components/shared/Button';
import { GlobusGradientIcon } from 'components/shared/Icons/GlobusGradientIcon';
import { LogoutGradientIcon } from 'components/shared/Icons/LogoutGradientIcon';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AdminGradientIconActive } from 'components/shared/Icons/AdminGradientIconActive';
import { HeaderAdminListProps } from './HeaderAdminList.props';

const HeaderAdminList = ({
  disabled,
  isAdmin,
  isManager,
  isConfirmedEmail,
  isTestUser,
  handleOpenLocalPreferencesModal,
  setIsOpenLocalPreferencesModal,
  resetCookies,
  goToKokocID,
}: HeaderAdminListProps) => {
  const { t } = useTranslation();

  return (
    <ul className={classes['header__admin-list']}>
      {!disabled && (isAdmin || isManager) && isConfirmedEmail && (
        <li className={classes['header__admin-list-item']}>
          <NavLink
            to={`${process.env.REACT_APP_API}/globaladmin/`}
            className={classes.modalLink}
          >
            <div className={classes.modalInner}>
              <div className={classes.modalIcon}>
                <AdminGradientIconActive />
              </div>
              <span className={classes.modalName}>{t('Admin panel')}</span>
            </div>
          </NavLink>
        </li>
      )}

      {!RUSSIAN_LOCALE && isConfirmedEmail && (
        <li className={classes['header__admin-list-item']}>
          <Button
            onClick={() => {
              handleOpenLocalPreferencesModal();
              setIsOpenLocalPreferencesModal(true);
            }}
            className={clsx(classes.modalButton)}
          >
            <div className={classes.modalInner}>
              <div className={classes.modalIcon}>
                <GlobusGradientIcon />
              </div>
              <span className={classes.modalName}>
                {t('Local preferences')}
              </span>
            </div>
          </Button>
        </li>
      )}

      {!isTestUser && (
        <li className={classes['header__admin-list-item']}>
          <Button onClick={resetCookies} className={clsx(classes.modalButton)}>
            <div className={classes.modalInner}>
              <div className={classes.modalIcon}>
                <LogoutGradientIcon />
              </div>
              <span className={classes.modalName}>{t('Log out')}</span>
            </div>
          </Button>
        </li>
      )}

      {RUSSIAN_LOCALE && (
        <li className={classes.modalItem}>
          <Button onClick={goToKokocID} className={classes.modalButton}>
            <div className={classes.modalInner}>
              <div className={classes.modalIcon}>
                <LogoutGradientIcon />
              </div>
              <span className={classes.modalName}>{t('Passport KokocID')}</span>
            </div>
          </Button>
        </li>
      )}
    </ul>
  );
};

export default HeaderAdminList;
