import { makeAutoObservable, runInAction } from 'mobx';
import Finances, { QueryParamsTransactions } from 'services/finances';
import { PaginationResponse } from 'types/base';
import { Bill, FinancesConditions, FinancesTransaction } from 'types/finances';

class FinancesStore {
  error: string | null = null;
  conditions: null | FinancesConditions = null;
  isLoadingConditions = false;
  transactions: null | PaginationResponse<FinancesTransaction[]> = null;
  isLoadingTransactions = false;
  bills: Bill[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setBillsForPayment = (bills: Bill[]) => {
    this.bills = bills;
  };

  setError(error: string | null) {
    this.error = error;
  }

  loadConditions = async () => {
    if (this.conditions !== null) {
      return; // If conditions are not null anymore, we don't make a repeat request (the percentage won't change that often)
    }

    this.setLoadingConditions(true);
    this.setError(null);

    try {
      const response = await Finances.getConditions();
      runInAction(() => {
        this.conditions = response.data;
      });
    } catch (err) {
      runInAction(() => {
        this.setError('Failed to load conditions');
      });
    } finally {
      runInAction(() => {
        this.setLoadingConditions(false);
      });
    }
  };

  setLoadingTransactions(loading: boolean) {
    this.isLoadingTransactions = loading;
  }

  loadTransactions = async ({
    page,
    limit,
    ordering,
  }: QueryParamsTransactions) => {
    this.setLoadingTransactions(true);
    this.setError(null);

    try {
      const response = await Finances.getTransactions({
        page: page ?? 1,
        limit: limit ?? 10,
        ordering: ordering ?? undefined,
      });
      runInAction(() => {
        this.transactions = response.data;
      });
    } catch (err) {
      runInAction(() => {
        this.setError('Failed to load transactions');
      });
    } finally {
      runInAction(() => {
        this.setLoadingTransactions(false);
      });
    }
  };

  setLoadingConditions(loading: boolean) {
    this.isLoadingConditions = loading;
  }

  clearStore() {
    runInAction(() => {
      this.conditions = null;
      this.setLoadingConditions(false);
      this.setError(null);
      this.transactions = null;
      this.setLoadingTransactions(false);
      this.bills = [];
    });
  }
}

export const financesStore = new FinancesStore();
