import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { SuccessIcon } from 'components/shared/Icons/SuccessIcon';
import { Input } from 'components/shared/Input';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { useWindowWidth } from 'hooks/useWindowWidth';
import logo from 'assets/img/logo.png';
import logo_ru from 'assets/img/logo_ru.svg';
import desktop from 'assets/img/Restore/desktop.png';
import lock_ru from 'assets/img/Restore/lock_ru.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { RUSSIAN_LOCALE } from 'utils';
import { Link } from 'components/shared/Link';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { languages } from 'constants/languages';
import classes from './Restore.module.scss';
import RestoreModal from './RestoreModal';
import { useRestoreModal } from './useRestore';
import { ArrowNarrowLeft } from 'components/shared/Icons/ArrowNarrowLeft';

export const RestoreContainer = () => {
  const {
    i18n,
    changeLanguage,
    step,
    t,
    handleSubmit,
    submit,
    control,
    errors,
    isValid,
    email,
    handleOpenModal,
    DESKTOP,
    MOBILE,
    handleResend,
    setResendRecaptcha,
    setOpenModal,
    openModal,
  } = useRestoreModal();
  const { isMediaTablet } = useWindowWidth();

  return (
    <>
      <TitleAndDescription pageName="Restore" />

      <div className={clsx(classes.container, 'restore-container')}>
        <NavLink
          to={RUSSIAN_LOCALE ? '#' : `${process.env.REACT_APP_MAIN_SITE_URL}`}
        >
          <img
            src={RUSSIAN_LOCALE ? logo_ru : logo}
            alt=""
            className={clsx(classes.logo, RUSSIAN_LOCALE && classes.logo_ru)}
          />
        </NavLink>

        <div className={classes.left}>
          <img
            src={RUSSIAN_LOCALE ? lock_ru : desktop}
            alt=""
            className={classes.image}
          />
        </div>

        <div className={classes.right}>
          {!RUSSIAN_LOCALE && (
            <div className={classes.lang}>
              <MultiselectComponent
                data={languages}
                langSelect={true}
                defaultSelected={
                  languages[
                    languages.findIndex(
                      (lang) => lang.lang_code === i18n.language
                    )
                  ]?.id
                }
                setMultiselect={changeLanguage}
                className={classes['multiselect-lang']}
              />
            </div>
          )}

          {step === 0 && (
            <>
              <div className={classes.back}>
                <Link href="/login">
                  <ArrowNarrowLeft className={classes.icon} />
                </Link>
                <div className={classes.backText}>{t('Back')}</div>
              </div>
              <div className={classes.title}>{t('Forgot password')}?</div>
              <div className={classes.text}>
                {t('Enter your email to restore password')}
              </div>

              <form className={classes.form} onSubmit={handleSubmit(submit)}>
                <div className={classes.fields}>
                  <div className={classes['input-field']}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Input
                          label={t('Email')}
                          labelPosition={'left'}
                          type={'text'}
                          placeholder={t('Enter your email')}
                          value={field.value}
                          error={!!errors?.email}
                          onChange={field.onChange}
                        />
                      )}
                      name="email"
                    />
                    {errors?.email && !isMediaTablet && (
                      <TooltipContainer
                        text={errors.email?.message}
                        customClasses={'kit-ui-block'}
                        position={'top'}
                        className={classes.error}
                      >
                        <InfoIcon fill="#F5222D" width="18px" height="18px" />
                      </TooltipContainer>
                    )}
                  </div>
                </div>

                {errors?.email && isMediaTablet && (
                  <div className={classes['mobile-error']}>
                    {errors.email?.message}
                  </div>
                )}

                <div className={classes.captcha}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <ReCAPTCHA
                        sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                        onChange={field.onChange}
                        hl="en"
                        size="normal"
                      />
                    )}
                    name="recaptcha"
                  />
                </div>

                <div className={classes.buttons}>
                  <Button
                    className={clsx(classes.button, classes['submit-button'])}
                    theme="primary"
                    disabled={!isValid}
                  >
                    {t('Restore')}
                  </Button>
                </div>
              </form>
            </>
          )}

          {step === 1 && (
            <>
              <div className={classes.heading}>
                <SuccessIcon />
              </div>

              <div className={classes.body}>
                <div className={classes.text}>
                  {t(
                    'We’ve sent you a link for restoring password. Please check your email'
                  )}{' '}
                  {email}
                </div>

                <div className={classes.help}>
                  {t('Can’t find it?')}

                  {!isMediaTablet ? (
                    <>
                      {t('Check your spam folder or')}
                      <span
                        className={classes.link}
                        onClick={() => handleOpenModal(DESKTOP)}
                      >
                        {t('resend a link')}
                      </span>
                      .
                    </>
                  ) : (
                    <>
                      <div>{t('Check your spam folder or')}</div>
                      <span
                        className={classes.link}
                        onClick={() => handleOpenModal(MOBILE)}
                      >
                        {t('resend a link')}
                      </span>
                      .
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <RestoreModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title={t('Resend verification email')}
        description={t(
          'To resend the verification email, please complete the CAPTCHA below for security purposes'
        )}
        setRecaptcha={setResendRecaptcha}
        handleRestore={handleResend}
      />
    </>
  );
};
