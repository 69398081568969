import './index.css';
import { AuthProvider } from 'contexts/AuthContext';
import { CartProvider } from 'contexts/CartContext';
import { MarketPlaceProvider } from 'contexts/MarketPlaceContext';
import { UserProvider } from 'contexts/UserContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <UserProvider>
      <CartProvider>
        <MarketPlaceProvider>
          <App />
        </MarketPlaceProvider>
      </CartProvider>
    </UserProvider>
  </AuthProvider>
);
