import clsx from 'clsx';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { Grid } from 'components/common/Grid';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import { Link } from 'components/shared/Link';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { LABELS } from 'constants/onboarding';
import { HotDealsCounter } from 'containers/CardsContainer/HotDeals/HotDealsCounter';
import { RecommendedService } from 'containers/RecommendedService';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import { NotFoundPage } from 'pages/NotFoundPage';
import { useNavigate } from 'react-router-dom';
import { GridItem } from 'components/common/Grid/index';
import { useWindowWidth } from 'hooks/useWindowWidth';
import 'containers/CardsContainer/IntroTooltip.css';
import { CardManager } from './CardManager';
import { Cases } from './Cases';
import { Certificates } from './Certificates';
import { OurTeam } from './OurTeam';
import { Price } from './Price';
import classes from './ServiceDetail.module.scss';
import { ServiceInfo } from './ServiceInfo';
import { useServiceDetail } from './useServiceDetail';
import { getLangName } from 'tools/utils';
import { GoalType } from '../../types/solutions';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';

export const ServiceDetailContainer = observer(() => {
  const navigate = useNavigate();
  const { width } = useWindowWidth();
  const {
    serviceInfo,
    hasError,
    t,
    i18n,
    marketPlaceStore,
    dealsDates,
    hotDealDiscountText,
    userStore,
    deals,
    isHotDeal,
  } = useServiceDetail();
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const activeIds = [serviceInfo?.id];
  const spanishLocale = i18n.language === 'es';

  if (serviceInfo?.notFound || hasError) {
    return <NotFoundPage />;
  }

  const enableHints = true;

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'SOLUTIONS_DETAILS';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('SOLUTIONS_DETAILS').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  return (
    <>
      <TitleAndDescription
        pageName="ServiceDetail"
        title={getLangName(serviceInfo, 'name', i18n)}
      />

      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <div className={classes.wrapper}>
        <Link className={classes.back} href="/" onClick={() => navigate(-1)}>
          <PurpleArrowLeftIcon /> {t('Back to Solutions')}
        </Link>

        <Grid className={classes.grid} direction={'row'}>
          <GridItem col={{ default: 12, xsup: 8 }} className={classes.left}>
            <ServiceInfo
              data={serviceInfo}
              hotDeals={marketPlaceStore.dataTimeHotDeals}
              deals={dealsDates}
            />

            {serviceInfo?.description && serviceInfo?.description !== '' && (
              <OurTeam
                data={getLangName(serviceInfo, 'description', i18n)}
                title={t('Project stages')}
              />
            )}

            {serviceInfo?.goals?.length > 0 && (
              <div className={classes.goals}>
                <div className={classes.goals_title}>
                  {t('Expected results')}
                </div>
                <div className={classes.goals_container}>
                  {serviceInfo.goals?.map((goal: GoalType, index: number) => (
                    <div key={index} className={classes.goals_tag}>
                      {getLangName(goal, 'name', i18n)}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {width < 961 && (
              <CardManager
                name={serviceInfo?.product?.manager?.first_name}
                src={serviceInfo?.product?.manager?.image}
                alt="Manager"
                serviceId={serviceInfo?.id}
              />
            )}

            {serviceInfo?.certificate &&
              serviceInfo?.certificate?.length > 0 && (
                <Certificates data={serviceInfo?.certificate} />
              )}

            {serviceInfo?.case && serviceInfo?.case?.length > 0 && (
              <Cases data={serviceInfo.case} />
            )}
          </GridItem>

          {width > 960 && (
            <GridItem col={{ default: 12, xsup: 4 }} className={classes.price}>
              <div className={clsx(!enableHints && classes.sticky)}>
                {marketPlaceStore.dataTimeHotDeals.activeDeals &&
                  !marketPlaceStore.dataTimeHotDeals.featureDeals &&
                  isHotDeal && (
                    <div className={classes.deals}>
                      <div
                        className={clsx(
                          classes.deal,
                          spanishLocale && classes.spanish
                        )}
                      >
                        {deals?.cost_change_type === 'MONEY' && (
                          <FormatPrice
                            currency={userStore.user?.currency?.symbol}
                            price={undefined}
                            withMinus={true}
                          />
                        )}
                        {hotDealDiscountText}
                      </div>
                      <div className={classes.timer}>
                        <HotDealsCounter inner small detail />{' '}
                      </div>
                    </div>
                  )}

                <div className={classes['price-block']}>
                  <Price
                    data={serviceInfo}
                    deals={dealsDates}
                    nameSource={serviceInfo.name}
                  />
                </div>

                <div className={classes.manager}>
                  <CardManager
                    name={serviceInfo?.product?.manager?.first_name}
                    src={serviceInfo?.product?.manager?.image}
                    alt="Manager"
                    serviceId={serviceInfo?.id}
                  />
                </div>
              </div>
            </GridItem>
          )}
        </Grid>

        {activeIds.length && (
          <RecommendedService
            ids={activeIds as number[]}
            nameSource={serviceInfo.name}
          />
        )}

        {serviceInfo && width < 961 && (
          <GridItem col={{ default: 12, xsup: 4 }} className={classes.price}>
            <Price data={serviceInfo} className="short" deals={dealsDates} />
          </GridItem>
        )}
      </div>
    </>
  );
});
