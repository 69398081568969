import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const InvoiceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M5.99999 7L7.33332 8.33333L10.3333 5.33333M13.3333 14V5.2C13.3333 4.0799 13.3333 3.51984 13.1153 3.09202C12.9236 2.71569 12.6176 2.40973 12.2413 2.21799C11.8135 2 11.2534 2 10.1333 2H5.86666C4.74655 2 4.1865 2 3.75868 2.21799C3.38235 2.40973 3.07639 2.71569 2.88464 3.09202C2.66666 3.51984 2.66666 4.0799 2.66666 5.2V14L4.49999 12.6667L6.16666 14L7.99999 12.6667L9.83332 14L11.5 12.6667L13.3333 14Z"
      fill="none"
      stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
