import classes from './Radio.module.scss';
import { RadioProps } from './Radio.props';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { RUSSIAN_LOCALE } from 'utils';

export const Radio = ({
  name,
  disabled,
  checked,
  value,
  label,
  description,
  onChange,
  classNameTitle,
  classNameDescription,
}: RadioProps) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(checked as boolean);
  }, [checked]);

  return (
    <label className={classes.radio}>
      <input
        name={name}
        type="radio"
        className={classes.hidden}
        disabled={disabled}
        checked={check}
        value={value.id}
        onChange={() => onChange && onChange(value)}
      />

      <span className={classes.visible}></span>

      {label && (
        <div className={classes.content}>
          <span
            className={clsx(
              classes.label,
              RUSSIAN_LOCALE && classes.labelRU,
              classNameTitle
            )}
          >
            {label}
          </span>

          {description && (
            <span
              className={clsx(
                classes.description,
                RUSSIAN_LOCALE && classes.descriptionRU,
                classNameDescription
              )}
            >
              {description}
            </span>
          )}
        </div>
      )}
    </label>
  );
};
