import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { useCallback, Dispatch, SetStateAction } from 'react';
import { pushToDataLayer } from 'tools/analytics';

export const useFavorites = (
  id: number,
  isFavorite: boolean,
  setIsFavorite: Dispatch<SetStateAction<boolean>>
) => {
  const marketPlaceStore = useMarketPlaceStore();

  const changeFavorites = useCallback(async () => {
    if (!isFavorite) {
      marketPlaceStore.addServiceToFavorite(id);
      pushToDataLayer('addToFavorite', 'buttonClick', 'GAForms');
      setIsFavorite(true);
    } else {
      marketPlaceStore.removeServiceFromFavorite(id);
      setIsFavorite(false);
    }
  }, [isFavorite]);

  return changeFavorites;
};
