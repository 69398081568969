import clsx from 'clsx';
import React from 'react';
import { ClearIcon } from 'components/shared/Icons/ClearIcon';
import { SearchGradientIcon } from 'components/shared/Icons/SearchGradientIcon';
import { Input } from 'components/shared/Input';
import i18n from 'i18n/i18n';
import { Link } from 'react-router-dom';
import CustomText from './CustomText';
import classes from './SearchComponent.module.scss';
import { SearchComponentProps } from './SearchComponent.props';
import { useSearchComponent } from './useSearchComponent';
import { getLangName } from 'tools/utils';
import { SolutionType } from 'types/solutions';
import { ProjectProps } from 'types/project';

export const SearchComponent = ({
  setOpenSearch,
  disabled,
}: SearchComponentProps) => {
  const {
    ref,
    search,
    setSearch,
    t,
    setOpen,
    setFocus,
    searchNavigate,
    clearSearch,
    open,
    searchSolutions,
    setSearchSolutions,
    searchProjects,
  } = useSearchComponent(setOpenSearch);

  return (
    <div className={classes['search']}>
      <div
        className={classes['search__background']}
        onClick={() => setOpenSearch(false)}
      />

      <div className={classes['search__container']} data-step-solutions-3>
        <div ref={ref} className={classes['search__wrapper']}>
          <Input
            value={search}
            onChange={setSearch}
            type="text"
            className={clsx(classes['search__input'])}
            placeholder={t('Search')}
            disabled={disabled}
            onFocus={() => {
              setOpen(true);
              setFocus(true);
            }}
            onBlur={() => setFocus(false)}
            onKeyDown={(e) => searchNavigate(e)}
          />

          <div className={classes['search__btn']}>
            <SearchGradientIcon />
          </div>

          {search && (
            <div
              className={classes['search__clear']}
              onClick={() => clearSearch()}
            >
              <ClearIcon />
            </div>
          )}

          {search && open && (
            <div className={classes['search__dropdown']}>
              {searchSolutions.length ? (
                <>
                  <span className={classes['search__dropdown-title']}>
                    {t('Solutions')}
                  </span>

                  <ul className={classes['search__dropdown-list']}>
                    {searchSolutions?.map((item: SolutionType) => (
                      <li
                        className={classes['search__dropdown-item']}
                        key={item.slug}
                      >
                        <Link
                          to={`/solutions/${item.slug}`}
                          onClick={() => {
                            setSearch('');
                            setSearchSolutions([]);
                          }}
                        >
                          <CustomText
                            text={getLangName(item, 'name', i18n)}
                            search={search}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}

              {searchProjects.length ? (
                <>
                  <span className={classes['search__dropdown-title']}>
                    {t('Projects')}
                  </span>

                  <ul className={classes['search__dropdown-list']}>
                    {searchProjects?.map((item: ProjectProps) => (
                      <li
                        className={classes['search__dropdown-item']}
                        key={item.id}
                      >
                        <Link
                          to={`/projects/${item.id}`}
                          onClick={() => {
                            setSearch('');
                            setSearchSolutions([]);
                          }}
                        >
                          <CustomText
                            text={getLangName(item, 'name', i18n)}
                            search={search}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}

              {searchSolutions.length || searchProjects.length ? (
                <Link
                  to={{
                    pathname: '/search-results',
                    hash: `from=${encodeURIComponent(
                      window.location.pathname
                    )}&search=${encodeURIComponent(search)}`,
                  }}
                  state={{ search: search }}
                  className={classes['search__see-all']}
                  onClick={() => {
                    setOpen(false);
                    setOpenSearch(false);
                  }}
                >
                  {t('See all')}
                </Link>
              ) : (
                <p className={classes['search__not-found']}>
                  {t('No results found for')}
                  <span>
                    {' «'}
                    {search}
                    {'»'}
                  </span>{' '}
                  {'. '} {t('Please check the spelling or use different words')}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
