import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classes from './EmployeesInvite.module.scss';
import { Input } from 'components/shared/Input';
import { SelectNew } from 'components/shared/SelectNew/SelectNew';
import { Button } from 'components/shared/Button';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { ModalMobile } from 'components/shared/ModalMobile';
import { useState } from 'react';
import { Radio } from 'components/shared/Radio';
import { OptionTypeString } from 'types/util-types';
import User from 'services/user';
import { EmployeesInviteProps } from './EmployeesInvite.props';
import { employeesRoles } from '../employeesRoles';
import { ShowNotification } from 'tools/showNotification';
import { emailCheckReg } from 'constants/matches';

const EmployeesInvite = ({ client, getUsers }: EmployeesInviteProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(emailCheckReg, t('Enter an existing email address'))
      .required(t('Enter an existing email address')),
    role: yup
      .object({
        id: yup.string().required(t('Role is required')),
      })
      .required(),
  });

  const { isSmallLaptop } = useWindowWidth();

  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleShowInviteModal = () => {
    setShowInviteModal(true);
  };

  const handleCloseInviteModal = () => {
    setShowInviteModal(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      role: employeesRoles[2],
    },
    mode: 'onSubmit',
  });

  const onSubmit = async (data: any) => {
    try {
      const result = await User.getUserPermissions(
        client.id,
        data.email,
        data.role.id
      );

      if (result.data.status === true) {
        ShowNotification({
          type: 'success',
          title: t('Invitation sent'),
          children: t('The invitation will arrive by email within 15 minutes'),
        });
        !isSmallLaptop && handleCloseInviteModal();
        await getUsers();
        reset();
      }
    } catch (error) {
      console.error('Error fetching user permissions:', error);
    }
  };

  const [isInputFocused, setIsInputFocused] = useState(false);

  const emailValue = watch('email');

  return (
    <>
      {isSmallLaptop ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ position: 'relative', zIndex: 1 }}
        >
          <div className={classes.inputWrap}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  bigInput
                  inputLabel={true}
                  className={classes.input}
                  type="text"
                  placeholder={t('Invite employee by work email')}
                  isBackground
                  error={errors.email?.message}
                  maxLength={50}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                  onMouseEnter={() => setIsInputFocused(true)}
                  onMouseLeave={() => setIsInputFocused(false)}
                  errorAbsolute
                />
              )}
            />

            <div className={classes.inputRight}>
              {emailValue && (
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <SelectNew
                      select={employeesRoles?.find(
                        (item: OptionTypeString) => item?.id === field?.value.id
                      )}
                      className={classes.selectRole}
                      title={t('Role')}
                      placeholder={t('Role')}
                      options={employeesRoles}
                      isSolutionForm
                      onSelect={(item: OptionTypeString) =>
                        field.onChange(item)
                      }
                      isInputFocused={isInputFocused}
                      isInInput
                    />
                  )}
                />
              )}

              <Button
                type="submit"
                className={classes.button}
                theme="primary"
                size="middle"
                disabled={emailValue.length === 0}
              >
                {t('Invite')}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <>
          <Button
            theme="primary"
            size="big"
            className={classes.inviteEmployee}
            onClick={handleShowInviteModal}
          >
            <span>{t('Invite employee')}</span>
          </Button>

          <ModalMobile
            title=""
            isOpen={showInviteModal}
            onClose={handleCloseInviteModal}
            isBasicHeader={false}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.inner}>
                <div className={classes.header}>
                  <h3 className={classes.title}>{t('Invitation')}</h3>
                </div>

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      bigInput
                      inputLabel={true}
                      className={classes.input}
                      type="text"
                      placeholder={t('Employee work email')}
                      isBackground
                      error={errors.email?.message}
                      maxLength={50}
                    />
                  )}
                />

                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <div className={classes.radioGroup}>
                      {employeesRoles.map((role: OptionTypeString) => (
                        <Radio
                          key={role.id}
                          name="role"
                          value={role}
                          checked={field.value?.id === role.id}
                          onChange={(item) => field.onChange(item)}
                          label={role.name ? t(role.name) : ''}
                          description={
                            role.description ? t(role.description) : ''
                          }
                        />
                      ))}
                    </div>
                  )}
                />

                <Button
                  type="submit"
                  className={classes.button}
                  theme="primary"
                  size="middle"
                  disabled={emailValue.length === 0}
                >
                  {t('Invite')}
                </Button>
              </div>
            </form>
          </ModalMobile>
        </>
      )}
    </>
  );
};

export default EmployeesInvite;
