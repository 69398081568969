import { Button } from 'components/shared/Button';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { useCartStore } from 'contexts/CartContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Promo.module.scss';
import { PromoProps } from './Promo.props';

export const Promo = ({
  applyCouponHandler,
  coupon,
  couponError,
  setCouponError,
  withdrawCouponHandler,
  errorMessage,
  couponDiscount,
  totalWithDiscounts,
}: PromoProps) => {
  const [disabled, setDisabled] = useState(false);
  const [couponInput, setCouponInput] = useState('');
  const [focused, setFocused] = useState(false);
  const cartStore = useCartStore();
  const cart = cartStore.cart;
  const discountTotalSumOfCart = cart?.coupon_discount?.total_sum_of_cart;

  const { t } = useTranslation();

  useEffect(() => {
    if (coupon) {
      setCouponInput(coupon);
      setDisabled(true);
    } else {
      setCouponInput('');
      setDisabled(false);
    }
  }, [coupon]);

  useEffect(() => {
    if (couponInput === '' && !errorMessage) {
      setCouponError(false);
    }
  }, [couponInput, errorMessage]);

  useEffect(() => {
    if (
      (!cart?.coupon_discount || discountTotalSumOfCart) &&
      Number(discountTotalSumOfCart) - Number(couponDiscount) >
        totalWithDiscounts
    ) {
      withdrawCouponHandler(false);
    }
  }, [totalWithDiscounts, cart?.coupon_discount]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.input}>
        <Input
          placeholder={t('Promo code')}
          onChange={(e) => {
            setCouponInput(e);
            setCouponError(false);
          }}
          value={couponInput}
          error={couponError}
          disabled={disabled}
          onFocus={() => {
            setFocused(true);
            setCouponError(false);
          }}
        />

        {disabled ? (
          <CrossIcon
            width={15}
            height={14}
            className={classes.btn}
            onClick={() => withdrawCouponHandler(true)}
          />
        ) : !couponError && (!errorMessage || couponInput || focused) ? (
          <Button
            theme={'text'}
            className={classes.btn}
            disabled={!couponInput}
            onClick={() => {
              applyCouponHandler(couponInput);
              setFocused(false);
            }}
          >
            {t('Apply')}
          </Button>
        ) : (
          <TooltipContainer
            text={
              errorMessage
                ? errorMessage
                : t("Sorry, can't apply this promo code")
            }
            customClasses={'kit-ui-block'}
            position={'top'}
            className={classes.error_msg}
          >
            <InfoIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>
    </div>
  );
};
