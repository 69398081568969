import classes from './LinksPolitic.module.scss';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from 'i18n/i18n';
import { RUSSIAN_LOCALE } from 'utils';

export const LinksPolitic = () => {
  const { t, i18n } = useTranslation();

  const links = [
    {
      condition: !RUSSIAN_LOCALE,
      href: `https://kit.global/${
        i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
      }terms/`,
      text: t('Terms of service'),
    },
    {
      condition: RUSSIAN_LOCALE,
      href: 'https://kokocgroup.ru/privacy/',
      text: t('Privacy Policy (single)'),
    },
    {
      condition: !RUSSIAN_LOCALE,
      href: `https://kit.global/${
        i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
      }privacy-policy/`,
      text: t('Privacy Policy (single)'),
    },
    {
      condition: true,
      href: RUSSIAN_LOCALE
        ? process.env.REACT_APP_RU_COOKIES_CONCENT_URL || ''
        : `https://kit.global/${
            i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
          }cookie-policy/`,
      text: t('Cookie Policy_footer'),
    },
  ];

  return (
    <ul className={classes['politic-links']}>
      {links.map(
        (link, index) =>
          link.condition && (
            <li key={index}>
              <a
                href={link.href}
                target="_blank"
                className={classes['politic-links__link']}
                rel="noreferrer"
              >
                {link.text}
              </a>
            </li>
          )
      )}
    </ul>
  );
};
