import { Logo } from 'components/common/Logo';
import classes from './PermissionErrorContainer.module.scss';
import { ChatMessageIcon } from 'components/shared/Icons/ChatMessageIcon';
import clsx from 'clsx';
import { RUSSIAN_LOCALE } from 'utils';
import { useTranslation } from 'react-i18next';
import LogoIcon from 'assets/img/LogoIcon';
import { BrokenCardIcon } from 'components/shared/Icons/BrokenCardIcon';

type Props = {
  isPermissionError: boolean;
  isLocaleError: boolean;
};

export default function PermissionErrorContainer({
  isLocaleError,
  isPermissionError,
}: Props) {
  const { t } = useTranslation();

  const localeErrorTemplate = (
    <div
      className={clsx(
        classes.content,
        classes.content__locale,
        RUSSIAN_LOCALE && classes['content__locale--ru']
      )}
    >
      <LogoIcon />
      <div className={classes.content__text}>
        <h1>
          {t('Sorry, access to our platform is restricted in your country')}
        </h1>
      </div>
    </div>
  );

  const permissionErrorTempalate = (
    <>
      <div className={classes.logo}>
        <Logo />
      </div>
      <div
        className={clsx(
          classes.content,
          RUSSIAN_LOCALE && classes['content--ru']
        )}
      >
        {RUSSIAN_LOCALE ? <BrokenCardIcon /> : <ChatMessageIcon />}
        <div className={classes.content__text}>
          <h1>{t('Sorry, you do not have access to perform this action')}</h1>
          <span>
            {t('This restriction ensures security and proper access control')}
          </span>
        </div>
      </div>
    </>
  );

  return (
    <div
      className={clsx(
        classes.wrapper,
        RUSSIAN_LOCALE && classes['wrapper--ru']
      )}
    >
      {isLocaleError && !isPermissionError && localeErrorTemplate}
      {isPermissionError && permissionErrorTempalate}
    </div>
  );
}
