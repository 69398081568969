import api from './http';

export default class Search {
  static async getSearchResults(resource: string, searchTerm: string) {
    return api.get(
      `/api/v1/search/?resource=${encodeURIComponent(resource)}&search=${encodeURIComponent(searchTerm)}`
    );
  }

  static async getFastSearchResults(searchTerm: string) {
    return api.get(
      `/api/v1/search/fast_search/?search=${encodeURIComponent(searchTerm)}`
    );
  }
}
