import classes from './Employees.module.scss';
import { TFunction } from 'i18next';
import { UserOnClient } from 'types/user';
import { getLangName } from 'tools/utils';
import i18n from 'i18n/i18n';

export const columnsArrayEmployees = () => [
  {
    header: '',
    accessor: 'serialNumber',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: 'Name',
    accessor: 'user',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: 'Work email',
    accessor: 'email',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: 'Role',
    accessor: 'role',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: 'Menu_empty',
    accessor: 'menu',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
];

export const tableDataEmployees = (users: UserOnClient[], t: TFunction) =>
  users?.map((user, index) => ({
    key: `user-${user.id}`,
    id: user.id,
    serialNumber: {
      id: index + 1,
      value: index + 1,
      className: classes.table__header,
    },
    user: {
      id: user.id,
      value: user.full_name ? t(user.full_name) : t('Invite sent'),
      className: classes.table__header,
      description: {
        text: t(user.full_name),
        className: classes.table__text,
      },
    },
    email: {
      id: user.id,
      value: user.email,
      className: classes.table__header,
      description: {
        text: user.email,
        className: classes.table__text,
      },
    },
    role: {
      email: user.email,
      id: user.id,
      label: getLangName(user.permission, 'name', i18n),
      type: user.permission.type,
      className: classes.table__header,
    },
    menu: {
      id: user.id,
      isMenu: true,
      full_name: user.full_name,
      email: user.email,
      permission: user.permission,
      className: classes.table__header,
    },
  }));
